/*******************************
     User Global Variables
*******************************/

/* COLOURS
BRAND VERY LIGHT: #fffbec
BRAND LIGHT PRIMARY: #ffe17c
BRAND PRIMARY: #FFCC00
BRAND SECONDARY VERY LIGHT: #f9f6ff;
BRAND SECONDARY LIGHT: #af9eff
BRAND SECONDARY: #6556FF
BRAND RED: #E56B6F
BRAND DARK RED: #B56576
BRAND AMBER: #FFD743
BRAND DARK GREEN: #338450
BRAND GREEN: #5DC080
 */

i {
  font-family: Icons !important;
}

i.outline {
  font-family: outline-icons !important;
}

.brand-icon {
  font-family: brand-icons !important;
}

* {
  font-family: 'Source Sans Pro', sans-serif !important;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

/* Some extra on top of semantic UI, will flesh out in due course */

.center {
  margin: 0 auto;
  width: 50%;
}

.centered {
  text-align: center !important;
}

.negative.ui.button {
  margin-top: 10px;
}

.hidden {
  display: none;
}

.large {
  font-size: 200%;
}

.pyxy-value {
  font-size: 300%;
  color: #ffae00;
}

.colored {
  color: #ffae00;
}

.end {
  padding-top: 68px;
}

.ui.sidebar {
  overflow: visible !important;
}

.ui.segment.empty {
  height: 80vh;
}

.ui.segment.course {
  height: 30vh;
}

.course-image {
  object-fit: contain;
  display: block;
  max-width: 100%;
  width: auto;
  height: auto;
  margin: 0 auto 20px auto;
}

.basic-side-margin {
  margin: 0 4px 0 4px;
}

/****** FONTS ******/

.poiret {
  font-family: 'Poiret One';
}

/****** FORUM ******/

.forum-up {
  cursor: pointer;
  color: #c6c6c6;
  transition: 0.2s;
}

.forum-up:hover {
  color: #ffcc00;
  transform: scale(1.5);
  transition: 0.25s;
}

.delete-comment {
  cursor: pointer;
  color: #c50f23;
  transition: 0.2s;
}

.delete-comment:hover {
  color: #e56b6f;
  transform: scale(1.5);
  transition: 0.25s;
}

.delete-comment-text {
  cursor: pointer;
  color: #c50f23 !important;
  transition: 0.2s;
}

.delete-comment-text:hover {
  color: #e56b6f !important;
  transition: 0.25s;
}

.cancel-text {
  cursor: pointer;
  color: rgba(0, 0, 0, 0.4) !important;
  transition: 0.2s;
}

.cancel-text:hover {
  color: rgba(0, 0, 0, 0.8) !important;
  transition: 0.25s;
}

.menu-no-padding {
  padding: none !important;
  font-size: 1em !important;
  cursor: pointer !important;
}

.menu-no-padding:hover {
  color: rgba(0, 0, 0, 0.8) !important;
}

.notifications.menu {
  display: block;
  width: max(30vw, 240px);
}

.mobile.notifications.menu {
  left: -100px !important;
  top: 100% !important;
}

i.notifications.icon {
  font-size: 2em !important;
}

.notification-meta {
  font-size: 0.85em;
  margin-top: 4px;
}

.main-topic-forum-edit {
  cursor: pointer;
  margin-left: 0.75em;
  color: rgba(0, 0, 0, 0.4);
}

.main-topic-forum-edit:hover {
  color: rgba(0, 0, 0, 0.8);
}

#basic-button {
  box-shadow: 0 0 0 1px #fc0 inset !important;
  transition: 0.2s;
}

#basic-button:hover {
  color: #ffffff !important;
  background-color: #fc0 !important;
  transition: 0.3s;
}

#basic-secondary-button {
  box-shadow: 0 0 0 1px #6556ff inset !important;
  transition: 0.2s;
}

#basic-secondary-button:hover {
  color: #ffffff !important;
  background-color: #6556ff !important;
  transition: 0.3s;
}

#basic-delete-button {
  box-shadow: 0 0 0 1px #e56b6f inset !important;
  transition: 0.2s;
}

#basic-delete-button:hover {
  color: #ffffff !important;
  background-color: #e56b6f !important;
  transition: 0.3s;
}

#basic-course-button {
  box-shadow: 0 0 0 2px #fc0 inset !important;
  border-radius: 50px;
}

#basic-secondary-course-button {
  box-shadow: 0 0 0 2px #6556ff inset !important;
  background-color: #fff;
  border-radius: 50px;
}

#basic-red-button {
  box-shadow: 0 0 0 2px #e56b6f inset !important;
  background-color: #fff;
  border-radius: 50px;
}

#primary-course-button {
  background: #fc0 !important;
  color: #000 !important;
  border-radius: 50px;
}

#secondary-course-button {
  background: #6556ff !important;
  color: #ffffff !important;
  border-radius: 50px;
}

#green-course-button {
  background: #5dc080 !important;
  color: #ffffff !important;
  border-radius: 50px;
}

#primary-button {
  background: #fc0 !important;
  color: #ffffff !important;
  border-radius: 50px;
  transition: 0.25s;
}

#primary-button:hover {
  color: #ffffff !important;
  box-shadow: 0px 4px #ffe17c !important;
  -webkit-transform: translateY(-4px);
  transform: translateY(-4px);
  transition: 0.2s;
}

#secondary-button {
  background: #6556ff !important;
  color: #ffffff !important;
  border-radius: 50px;
  transition: 0.25s;
}

#secondary-button:hover {
  color: #ffffff !important;
  box-shadow: 0px 4px #af9eff !important;
  -webkit-transform: translateY(-4px);
  transform: translateY(-4px);
  transition: 0.2s;
}

#red-button {
  background: #e56b6f !important;
  color: #ffffff !important;
  border-radius: 50px;
  transition: 0.25s;
}

#red-button:hover {
  color: #ffffff !important;
  box-shadow: 0px 4px #ffcad1 !important;
  -webkit-transform: translateY(-4px);
  transform: translateY(-4px);
  transition: 0.2s;
}

#cancel-button {
  border-radius: 50px;
}

#course-category-button {
  background-color: #00000000;
  border: 1px solid #fc0;
  font-weight: 400;
  border-radius: 50px;
  margin: 0 8px 8px 0;
}
#course-category-button-active {
  background-color: #fc0;
  color: #fff;
  font-weight: 700;
  border-radius: 50px;
  border: solid 1px #ffb500 !important;
  margin: 0 8px 8px 0;
}

#course-tag-button-primary {
  background-color: #ffedb0;
  font-weight: 400;
  border-radius: 50px;
  margin: 4px;
}

#course-tag-button-secondary {
  background-color: #d0c5ff;
  font-weight: 400;
  border-radius: 50px;
  margin: 4px;
  cursor: default;
}

.comment-action {
  cursor: pointer;
  display: inline-block;
  margin: 0 0.75em 0 0;
  color: rgba(0, 0, 0, 0.4);
}
.comment-action:hover {
  color: rgba(0, 0, 0, 0.8);
}

#primary-icon {
  color: #fc0;
}

#white-icon {
  color: #fff;
}

.primary-card-content {
  background-color: #ffe17c !important;

  transition: 0.2s;
}

.primary-card-content:hover {
  background-color: #fc0 !important;

  transition: 0.3s;
}

.primary-card-content:hover #primary-card-header {
  color: #fff;
  transition: 0.3s;
}

.x-overflow {
  overflow-x: auto;
  flex-wrap: nowrap !important;
}

.x-overflow-desktop {
  overflow-x: auto;
  flex-wrap: nowrap !important;
}

.x-overflow-buttons {
  overflow-x: auto;
  white-space: nowrap;
}

.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.breadcrumb {
  line-height: 1.5 !important;
  padding: 8px;
}

.follow-button {
  cursor: pointer;
}

.fade-transition-enter {
  opacity: 0;
}
.fade-transition-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}
.fade-transition-exit {
  opacity: 0;
}
.fade-transition-exit-active {
  opacity: 1;
  transition: opacity 300ms;
}

.test {
  position: absolute;
  max-width: 100% !important;
}

.slide-right-enter {
  opacity: 0;
  transform: translateX(-100%);
}

.slide-right-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}

.slide-right-exit {
  opacity: 1;
  transform: translateX(0);
}

.slide-right-exit-active {
  opacity: 0;
  transform: translateX(100%);
  transition: opacity 300ms, transform 300ms;
}

.slide-left-enter {
  opacity: 0;
  transform: translateX(100%);
}

.slide-left-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}

.slide-left-exit {
  opacity: 1;
  transform: translateX(0);
}

.slide-left-exit-active {
  opacity: 0;
  transform: translateX(-100%);
  transition: opacity 300ms, transform 300ms;
}

.slide-down-explanation {
  animation: slide-down-explanation 300ms ease;
}

@keyframes slide-down-explanation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.slide-down {
  animation: slide-down 500ms ease;
}

@keyframes slide-down {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.slide-up {
  width: 100vw;
  left: 0px;
  bottom: 64px;
  top: 60px;
  height: auto;
  position: fixed;
  overflow-y: scroll;
  z-index: 100;
  background-color: #f9f6ff;
  animation: slide-up 300ms ease;
}

@keyframes slide-up {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}

.fade-in {
  animation: fade-in 300ms;
}

@keyframes fade-in {
  0% {
    opacity: 0;
    transform: scale(0.9);
  }
  100% {
    opacity: 1;
  }
}

@keyframes desktop-fade-in {
  0% {
    opacity: 0;
    transform: scale(0.9);
  }
  100% {
    opacity: 1;
  }
}

.mobile-list-slide-down {
  animation: list-slide-down 500ms;
}

.desktop-list-slide-down {
  animation: desktop-list-slide-down 500ms;
}

@keyframes list-slide-down {
  0% {
    height: 0;
    opacity: 0;
  }
  25% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    height: 180px;
  }
}

@keyframes desktop-list-slide-down {
  0% {
    height: 0;
    opacity: 0;
  }
  25% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    height: 152px;
  }
}

.more-info {
  font-size: 16px;
  margin-top: 16px;
  height: auto;
}

.animating-accordion {
  animation: animating-accordion 300ms ease;
}

@keyframes animating-accordion {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.rotating {
  animation: rotating 4s linear infinite;
}

@keyframes rotating {
  100% {
    transform: rotate(360deg);
  }
}

.live-course {
  animation: live-course 300ms;
}

@keyframes live-course {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

.flow-right {
  animation: flow-right 2000ms infinite;
}

@keyframes flow-right {
  0% {
    transform: translateX(-100vw) rotate(-360deg);
  }
  45% {
    transform: translateX(0vw);
  }
  55% {
    transform: translateX(0vw);
  }
  100% {
    transform: translateX(100vw) rotate(360deg);
  }
}

.cascade-fade.one {
  animation: cascade-fade 3000ms linear infinite;
}
.cascade-fade.two {
  animation: cascade-fade 3000ms linear infinite;
  animation-delay: 500ms;
}
.cascade-fade.three {
  animation: cascade-fade 3000ms linear infinite;
  animation-delay: 1s;
}

@keyframes cascade-fade {
  0% {
    opacity: 0;
    transform: scale(0.9);
  }
  50% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(0.9);
  }
}

.original-loader-enter {
  opacity: 0;
}
.original-loader-enter-active {
  opacity: 1;
  transition: opacity 00ms;
}
.original-loader-exit {
  opacity: 1;
}
.original-loader-exit-active {
  opacity: 0;
  transform: scale(1.5);
  transition: opacity 1000ms, transform 1000ms;
}

.quick-fade-enter {
  opacity: 0;
}

.quick-fade-enter-active {
  opacity: 1;

  transition: opacity 200ms;
}

.quick-fade-exit {
  opacity: 1;
}

.quick-fade-exit-active {
  opacity: 0;
  transition: opacity 200ms;
}

.mobile-footer {
  display: none;
}

.mobile-header {
  border: 1px solid #f3f3f3 !important;

  height: 60px;
  max-width: 100% !important;
  background-color: #fff !important;
}

@media only screen and (max-width: 767px) {
  .desktop-only {
    display: none !important;
  }

  .component-margin {
    margin: 60px 0 80px 0;
  }

  .courses-background {
    background-image: url('https://i.ibb.co/6Rz4Jr7/Untitled-design-1.png');
    background-color: #fff;
  }

  .question-background {
    background-image: url('https://i.ibb.co/g4HbwZr/Untitled-design-3.png');
    background-size: cover;
    background-color: #fff;
  }

  .quiz-background {
    background-image: none !important;
    background-color: #f9f6ff !important;
  }

  .practice-background {
    background-image: url(https://i.ibb.co/vjYvpxQ/Untitled-design-4.png);
    background-size: cover;
    background-color: #fff;
  }

  .top-positioning {
    padding-top: 16px !important;
  }

  .more-info {
    height: 60vh;
  }
}

@media only screen and (max-width: 767px) {
  .mobile-footer {
    display: block;
    height: 58px;
    position: fixed;
    bottom: 0px;
    width: 100%;
    margin-left: -1em !important;
    background-color: #fff;
    box-shadow: 0 0 8px -4px #b3b3b3;
    border-radius: 8px 8px 0 0;
    z-index: 102;
  }
}

@media only screen and (min-width: 768px) {
  .mobile-only {
    display: none !important;
  }
  .slide-up {
    bottom: 0px;
    top: 58px;
    left: auto;
    height: auto;
    border-radius: 10px;
    animation: desktop-fade-in 300ms ease;
  }

  .course-image {
    max-height: 500px;
  }
}

/* TO MATCH UI CONTAINER */

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slide-up {
    width: 723px;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slide-up {
    width: 933px;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

@media only screen and (min-width: 1200px) {
  .slide-up {
    width: 1127px;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

.desktop-scroll-arrow {
  position: absolute;
  cursor: pointer;
  color: #9c9c9c;
  transition: 0.2s;
}

.desktop-scroll-arrow:hover {
  transform: scale(1.2);
  transition: 0.3s;
  color: #585858;
}

.desktop-scroll-arrow.right {
  left: 100%;
}

.desktop-scroll-arrow.left {
  right: 100%;
}

.desktop-header {
  background-color: #fff;
  margin: 0 auto;
  border-bottom: solid 1px #dbdbdb;
}

/* .menu-column {
  display: flex !important;
  justify-content: center;
}

.menu-icon {
  display: block !important;
  margin: 0 auto !important;
}

.menu-label {
} */

.search-bar {
  width: 240px;
  margin-left: auto !important;
  margin-right: 0 !important;
}

/* .search-bar-active {
  max-width: 240px;
  margin-left: auto !important;
  margin-right: 0 !important;

  animation: search-bar-active 300ms ease;
}

@keyframes search-bar-active {
  0% {
    width: 44px;
  }
  100% {
    width: 240px;
  }
}

.search-bar-unfocused {
  max-width: 240px;
  margin-left: auto !important;
  margin-right: 0 !important;

  animation: search-bar-unfocused 300ms forwards;
}

@keyframes search-bar-unfocused {
  0% {
    width: 240px;
  }
  100% {
    width: 44px;
  }
} */

.primary-input {
  border-radius: 50px !important;
  border: 1px solid #fc0 !important;
}

.primary-input-large {
  border-radius: 8px !important;
  border: 1px solid #fc0 !important;
}

.primary-input-large.ui.active.selection.dropdown {
  border-radius: 8px 8px 0 0 !important;
  border: 1px solid #fc0 !important;
}

.primary-input-large.slide.down {
  border-radius: 0 0 8px 8px !important;
  border: 1px solid #fc0 !important;
}

.primary-input.right {
  border: solid 1px #338450 !important;
  background-color: #5dc080 !important;
}

.primary-input.wrong {
  border: solid 1px #b56576 !important;
  background-color: #e56b6f !important;
}

.course-card {
  border-radius: 16px !important;
}
.course-card.empty {
  background-color: #eeeeee;
  box-shadow: none;
  -webkit-box-shadow: none;
}

.course-card.empty.one {
  animation: pulse 1s infinite;
}

.course-card.empty.two {
  animation: pulse 1s infinite;
  animation-delay: 100ms;
}
.course-card.empty.three {
  animation: pulse 1s infinite;
  animation-delay: 200ms;
}
.course-card.empty.four {
  animation: pulse 1s infinite;
  animation-delay: 300ms;
}

.empty-header {
  height: 32px;
  width: 200px;
  display: inline-block;
  background-color: #eeeeee;
  border-radius: 8px !important;
  animation: pulse 1s infinite;
}

.empty-header.one {
  animation: pulse 1s infinite;
}

.empty-header.two {
  animation: pulse 1s infinite;
  animation-delay: 100ms;
}
.empty-header.three {
  animation: pulse 1s infinite;
  animation-delay: 200ms;
}
.empty-header.four {
  animation: pulse 1s infinite;
  animation-delay: 300ms;
}

@keyframes pulse {
  0% {
    /* transform: scale(1); */
    opacity: 0.5;
  }
  50% {
    /* transform: scale(1.05); */
    opacity: 1;
  }
  100% {
    /* transform: scale(1); */
    opacity: 0.5;
  }
}

#course-card-image {
  border-radius: 16px !important;
  padding: 12.5%;
  background: #fff;
}

#section-card-image {
  border-radius: 16px 16px 0 0 !important;
  padding: 12.5%;
  background: #fff;
}

.locked {
  background-color: #000 !important;
}

.text-link {
  color: #4183c4 !important;
  cursor: pointer;
}

.phone-explanation {
  max-width: 300px;
  margin: 16px auto;
  padding: 16px;
  border-radius: 16px;
  background-color: white;
  border: 2px solid #6556ff;
}

.courses-background {
  background-image: url('https://i.ibb.co/rvfgV6W/Untitled-design-6.png');
  background-size: repeat;
  background-color: #fff;
}

.practice-background {
  background-image: url('https://i.ibb.co/4pT8gT5/Untitled-design-5.png');
  background-size: cover;
  background-color: #fff;
}

.quiz-background {
  background-image: url('https://i.ibb.co/dD2PJ2z/Untitled-design-5.png');
  background-size: cover;
  background-color: #fff;
}

.back-arrow-absolute {
  position: absolute;
}

.top-positioning {
  padding-top: 74px;
}

.pyxy-label {
  background-color: #fff !important;
  color: #fc0 !important;
  border-radius: 50px !important;
  border: solid 2px #fc0 !important;
}

.course-progress-mobile {
  top: 60px;
  position: fixed;
  left: 0;
  width: 100%;
  height: 32px;
  padding: 4px;
  background-color: #fff;
  border: 1px solid #f3f3f3 !important;
  z-index: 101;
}

.course-progress-desktop {
  top: 58px;
  position: fixed;

  width: inherit;
  height: 56px;
  padding: 16px;
  background-color: #fff;
  border-bottom: 1px solid #f3f3f3 !important;
  z-index: 101;
}

.course-progress-item {
  display: inline-block;
  height: 24px;
  margin: 0 0.5px;
  background-color: #dedede;
  border-radius: 50px;
}

.course-progress-item.complete {
  background-color: #5dc080;
  cursor: pointer;
}

.course-progress-item.active {
  background-color: #6556ff;
  cursor: pointer;
  /* animation: course-progress-update 300ms; */
}

@keyframes course-progress-update {
  0% {
    opacity: 0%;
  }
  100% {
    opacity: 100%;
  }
}

.course-text {
  font-size: 16px;
  margin-bottom: 1em !important;
}

.course-text-question {
  font-size: 20px;
  margin-top: 2em !important;
  line-height: 1.4;
  padding: 16px;
}

.question-option {
  border: solid 1px #fc0 !important;
  padding: 12px 20px !important;
  border-radius: 50px;
  cursor: pointer;
}

.question-option.checked {
  background-color: #ffe17c;
  transform: scale(1.05);
  transition: 0.2s;
}

.question-option.right {
  border: solid 1px #338450 !important;
  background-color: #5dc080;
}

.question-option.wrong {
  border: solid 1px #b56576 !important;
  background-color: #e56b6f;
}

.question-option-text {
  font-size: 16px !important;
}

.text-divider {
  border-bottom: solid 1px #dedede;
  margin: auto;
}

.question-explanation {
  background-color: #f9f6ff !important;
  border-radius: 16px !important;
  font-size: 16px !important;
}

.question-explanation.quiz {
  background-color: #fdfdfd !important;
}

/* .pyxy-rocket {
  animation: pyxy-rocket 2500ms;
  animation-iteration-count: 1;
  z-index: 999;
} */

/* @keyframes pyxy-rocket {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  5% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  10% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  15% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  20% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  25% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  30% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  35% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  40% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  45% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  50% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
  100% {
    transform: translate(100px, -600px);
  }
} */

.flip-card {
  height: 200px;
  max-width: 300px;
  margin: 0 8px 4px;
  cursor: pointer;
  display: inline-block;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 16px !important;
  transition: 500ms !important;
  -webkit-transition: 500ms !important;
  transform-style: preserve-3d;
}

.flip-card-inner.active {
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute !important;
  width: 100%;
  height: 100%;
  margin: 0 !important;

  transition: 500ms !important;
  -webkit-transition: 500ms !important;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}

.flip-card-front {
  z-index: 2;
  -webkit-transform: rotateY(0deg);
  transform: rotateY(0deg);
}

.flip-card-back {
  -webkit-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
}

.card-text {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  padding: 16px;
  flex-wrap: wrap;
}

.card-text.front {
  font-size: 20px;
  background-color: #ffe17c;
}

.card-text.back {
  font-size: 16px;
  background-color: #af9eff;
}

.card-button {
  position: absolute;
  bottom: 8px;
  right: 8px;
}

.flip-card.closed {
  animation: closed-card 500ms forwards;
}

.flip-card.reopened {
  animation: closed-card 500ms reverse;
}

@keyframes closed-card {
  50% {
    opacity: 0;
  }
  100% {
    height: 0;
    opacity: 0;
  }
}

.card-item-enter {
  opacity: 0;
  height: 0;
}
.card-item-enter-active {
  opacity: 1;
  height: 200px;
  transition: opacity 600ms, height 300ms ease-in-out;
}
.card-item-exit {
  opacity: 1;
}
.card-item-exit-active {
  opacity: 0;
  height: 0;
  transition: opacity 150ms, height 300ms ease-in-out;
}

.brand-red {
  color: #e56b6f !important;
}

.brand-green {
  color: #5dc080 !important;
}

.brand-primary {
  color: #fc0 !important;
}

.brand-secondary {
  color: #6556ff !important;
}

.brand-red-background {
  background-color: #e56b6f !important;
}

.brand-green-background {
  background-color: #5dc080 !important;
}

.brand-primary-background {
  background-color: #fc0 !important;
}
.brand-secondary-background {
  background-color: #6556ff !important;
}

.desktop-course {
  position: absolute;
  left: 0;
  top: 58px;
  width: 100%;
}

.course-nav-bar {
  position: fixed;

  color: #fff;
  width: inherit;
  padding: 0 2vw;
}

.course-nav-bar.bottom {
  bottom: 24px;
}

.pyxy-header {
  color: #fc0;
  font-size: 175%;
}

.pyxy-header.active {
  animation: font-grow 500ms ease-in-out;
}

@keyframes font-grow {
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

.social-link-mobile {
  margin-right: 4px;
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.first.social-link-mobile {
  padding-left: 1rem !important;
}

.social-link-desktop {
  margin-bottom: 8px;
}

/* .first.social-link-desktop {
  margin 
} */

.helper-label {
  background-color: #ffe17c !important;
  border-radius: 50px !important;
}

.end-of-count {
  animation: end-of-count 1s;
}

@keyframes end-of-count {
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

.large-stat {
  font-weight: 700;
  font-size: 80px;
}

.medium-stat {
  font-weight: 700;
  font-size: 40px;
}

.small-stat {
  font-weight: 700;
  font-size: 20px;
}

.secondary-text {
  color: #6556ff;
}

.primary-text {
  color: #ffcc00;
}

.primary-background {
  background-color: #ffe17c;
}

.secondary-background {
  background-color: #af9eff;
}

.streak {
  color: #dadada;
  animation: fade-in 300ms;
}

.streak.active {
  color: #ffcc00;
}

#mobile-menu-icon {
  margin-bottom: 0 !important;
  font-size: 1.5em !important;
}

#mobile-menu-icon-active {
  margin-bottom: 0 !important;
  font-size: 2em !important;
  color: #6556ff;
  transition: font-size 150ms;
}

#mobile-menu-icon:hover,
#mobile-menu-icon:active,
#mobile-menu-icon-active:hover,
#mobile-menu-icon-active:active,
#mobile-header-icon:hover,
#mobile-header-icon:active {
  background-color: #fff !important;
}

#mobile-header-icon-active-notification {
  color: #6556ff !important;
  transform: rotate(10deg) scale(1.1);
  transition: 300ms;
}

#mobile-header-icon-active {
  color: #6556ff !important;
  transform: scale(1.1);
  transition: 300ms;
}

#no-border {
  border: none !important;
}

.mobile-footer-item {
  background-color: #fff !important;
}

.desktop-header-item {
  background-color: #fff !important;
  color: rgba(0, 0, 0, 0.87) !important;
  transition: 200ms !important;
}

.desktop-header-item:hover {
  color: #6556ff !important;
  transform: scale(1.1);
  transition: 300ms !important;
}

.no-background {
  background-color: #fdfdfd;
}

.purchase-button {
  animation: purchase 500ms infinite;
}

@keyframes purchase {
  0% {
    transform: scale(0.95);
  }
  70% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.95);
  }
}

.phone-flag {
  background-color: #fc0 !important;
  border: none !important;
  border-radius: 50px 8px 8px 50px !important;
  padding: 0 4px !important;
}

.selected-flag,
.selected-flag:hover,
.selected-flag:active {
  background-color: #fc0 !important;
  border: none !important;
  border-radius: 50px 8px 8px 50px !important;
}

.flag {
  border-radius: 50px;
}
